.ciras-nav {
  padding: 20px 0;
  margin: 20px;
  min-height: 102px;
  font-size: 1.5em;
  min-width: 90%;
}

.ciras-nav span {
  display: inline-block;
  height: 60px !important;
}

.ready-to-fish-svg {
  width: 75px !important;
  height: 60px !important;
}

.home .header-image {
  background-image: url(../../images/Placholder-File-2.jpg);
  background-position: center;
  background-size: cover;
  display: block;
  position: relative;
  color: #fff;
  padding: 1rem 0 1.5rem 0;
}

.home .header-image img {
  max-width: 450px;
}

.survey-info {
  text-align: center;
}

.survey-info svg {
  vertical-align: text-bottom;
}

.trending-species-fish {
  text-align: center;
}

.trending-species-fish img {
  display: block;
  margin: 0 auto;
  height: 7rem;
}

@media only screen and (min-width: 600px) {
  .trending-species-fish img {
    height: 12rem;
  }

  .ciras-nav {
    padding: 30px;
    font-size: 2em;
    min-width: 420px;
  }
}

.banner-main {
  width: 100%;
  height: 50vh;
  min-height: 400px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
}

.banner-content img {
  max-height: 300px;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;
  min-height: 1000px;
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: #000000cc; */
}

.youtube-video {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}
