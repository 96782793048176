.report-style-class {
  width: 100%;
  height: 700px;
}

.report-style-class-mobile {
  width: 100%;
  height: 5500px;
}

.tab-title {
  padding-left: 20px;
}

.container:has(.report-style-class-mobile) {
  padding: 0;
}
