.cdfw_footer p {
	text-align: center;
	color: white;
}

.bold {
	font-weight: bold;
}

.cdfw_footer {
	font-size: 0.9rem;
	line-height: 1em;
}
