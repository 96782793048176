.global-header.compact .alert-banner {
  opacity: inherit;
  position: relative;
  width: inherit;
  height: inherit;
}

.alert-banner .container {
  text-align: center;
}

.main-navigation.dropdown .sub-nav {
  min-width: 350px;
}

.language-settings .skiptranslate {
  text-align: center;
}

#ready-to-fish-header:hover path {
  fill: #045070 !important;
}

.first-level-link {
  height: 80px;
}

.first-level-link span {
  display: inline-block;
  height: 50px;
}

.ca-gov-icon-book {
  text-align: center;
  height: 50px;
  min-width: 75px;
}

@media (max-width: 767px) {
  .first-level-link span:first-of-type {
    width: 75px;
    text-align: center;
  }

  .first-level-btn {
    height: 80px;
  }

  .main-navigation:not(.collapse):not(.collapsing):not(.show) {
    display: none;
  }
}

@media (min-width: 768px) {
  .first-level-link span {
    display: block;
  }
}
