tfoot tr {
  display: none !important;
}

.sd-root-modern {
  max-width: 1280px;
  margin: 0 auto;
}

.sd-element[data-name="totalFish"] .sd-title,
.sd-element[data-name="totalKept"] .sd-title,
.sd-element[data-name="totalReleased"] .sd-title {
  text-align: center;
}

.sd-element[data-name="totalFish"] .sd-expression,
.sd-element[data-name="totalKept"] .sd-expression,
.sd-element[data-name="totalReleased"] .sd-expression {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.sd-element[data-name="fish-caught"] .sd-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.sd-element[data-name="fish-caught"] .sd-table th,
.sd-element[data-name="fish-caught"] .sd-table td {
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.sd-element[data-name="fish-caught"] .sd-question__content {
  padding-top: 0;
}

.sd-element[data-name="fish-caught"] {
  border: none;
  padding: 0;
}

.sd-element[data-name="fish-caught"] .sd-table-wrapper {
  margin: 0;
  min-width: 100%;
}

.sd-element[data-name="fish-caught"] .sd-table-wrapper:before,
.sd-element[data-name="fish-caught"] .sd-table-wrapper:after {
  width: 0;
  right: inherit;
}

.sd-element[data-name="fish-caught"]
  .sd-table
  .sd-table__cell-action--remove-row {
  min-width: 50px;
}

.sd-element[data-name="fish-caught"]
  .sd-table
  .sd-table__cell-action--remove-row
  .sd-action-bar {
  justify-content: center;
}

.sd-element[data-name="fish-caught"] .sd-table tr {
  display: table-row;
}

.sd-element[data-name="fish-caught"] .sd-table thead {
  display: table-header-group;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell {
  margin: 0;
  text-align: center;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-table__row {
  padding: 0;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell--error {
  display: none !important;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-action__icon {
  display: block !important;
}

.sd-element[data-name="fish-caught"]
  .sd-table
  .sd-action.sd-action.sd-matrixdynamic__remove-btn:after,
.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell::before,
.sd-element[data-name="fish-caught"]
  .sd-table:not(.sd-matrix__table)
  tr:not(.sd-table__row--expanded)::after {
  content: none;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell--empty,
.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell--actions {
  position: revert;
}

.sd-element[data-name="fish-caught"] .sd-table .sd-table__cell {
  display: table-cell;
  min-width: 0px;
}

.sd-body.sd-body--static:has(.sd-element[data-name="location-dropdown"]) {
  padding-bottom: 20px;
}

.sd-element[data-name="fish-table"] .sd-table td:last-child {
  margin-bottom: 20px;
}

.sd-element[data-name="fish-table"] .sd-matrixdynamic__footer {
  text-align: end;
}

.sd-element[data-name="fish-table"] .sd-action {
  display: inline;
}

.sd-element[data-name="fish-table"] .sd-table .sd-table__row {
  vertical-align: top;
}

.sd-element[data-name="fish-caught"] .sd-table__responsive-title {
  display: none;
}

.sd-element[data-name="FishSpecies"] > div.sd-error {
  display: none;
}

.sd-panel__content:has(.sd-element[data-name="FishSpecies"]) {
  padding-top: 0;
}

@media only screen and (min-width: 600px) {
  .sd-element[data-name="fish-caught"]
    .sd-table
    .sd-table__cell-action--remove-row
    .sd-action-bar {
    margin-right: 0;
  }
}

.location-note {
  text-align: center;
}

.btn-submit,
.survey-complete-text > h3,
.survey-complete-text > h5 {
  padding: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))))
    calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))))
    calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  text-align: center;
}

.survey-complete-text > h3,
.survey-complete-text > h5 {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-location-submit {
  padding-top: 0;
}

.recaptcha > div > div {
  margin: 0 auto 20px auto;
}

.sv-svg-icon {
  width: 32px !important;
  height: 32px !important;
}

.App:has(#survey-page) #ciras-footer {
  display: none;
}

.App:has(#survey-page) #main-content {
  padding-top: 0 !important;
}

.sd-page__title {
  width: 100%;
}

.dialog-image {
  text-align: center;
  padding-top: 20px;
}

.end-survey {
  padding-bottom: 80px;
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
}
